<template>
  <!-- BEGIN #content -->
  <div id="content" class="app-content">
    <!-- page header -->
    <h1 class="page-header">Analytics <small>stats, overview & performance</small></h1>

    <!-- daterangepicker -->
    <div class="d-flex align-items-center mb-3">
      <date-range-picker
        ref="picker"
        control-container-class="btn btn-default"
        :opens="dateRange.opens"
        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
        :singleDatePicker="dateRange.singleDatePicker"
        :timePicker="dateRange.timePicker"
        :timePicker24Hour="dateRange.timePicker24Hour"
        :showWeekNumbers="dateRange.showWeekNumbers"
        :showDropdowns="dateRange.showDropdowns"
        :autoApply="dateRange.autoApply"
        v-model="dateRange.range"
        @update="updateValues"
        :linkedCalendars="dateRange.linkedCalendars"
      >
        <template v-slot:input="picker">
          <i class="fa fa-fw fa-calendar ml-n1"></i>
          {{ picker.startDate | date }} - {{ picker.endDate | date }}
          <b class="caret"></b>
        </template>
      </date-range-picker>
      <span class="ml-2">
        compared to
        <span>
          {{ dateRange.range.prevStartDate }} -
          {{ dateRange.range.prevEndDate }}
        </span>
      </span>
    </div>

    <!-- BEGIN card-columns -->
    <b-card-group columns class="mb-4">
      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-2">
          <div class="flex-fill font-weight-600 fs-16px">Total sales</div>
          <a href="#">View report</a>
        </div>

        <!-- stats -->
        <div class="d-flex align-items-center mb-4 h3">
          <div>$821.50</div>
          <small class="fw-400 ml-auto text-success">+5%</small>
        </div>

        <!-- chart -->
        <div>
          <div class="fs-13px font-weight-600 mb-3">SALES OVER TIME</div>
          <div class="chart mb-2">
            <line-chart :data="chart1.data" :options="chart1.options" class="w-100" style="height: 190px"></line-chart>
          </div>
          <div class="d-flex align-items-center">
            <i class="fa fa-square text-gray-300 mr-2 ml-auto"></i>
            <span class="fs-12px mr-4">{{ prevDate }}</span>
            <i class="fa fa-square text-primary mr-2"></i>
            <span class="fs-12px">{{ todayDate }}</span>
          </div>
        </div>
      </b-card>
      <!-- END card -->

      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-2">
          <div class="flex-fill font-weight-600 fs-16px">
            Online store sessions
          </div>
          <a href="#">View report</a>
        </div>

        <!-- stats -->
        <div class="d-flex align-items-center mb-4 h3">
          <div>39</div>
          <small class="fw-400 ml-auto text-danger">-2.5%</small>
        </div>

        <!-- stats small -->
        <b-row class="mb-4">
          <b-col cols="6">Visitors</b-col>
          <b-col cols="3" class="text-center">2</b-col>
          <b-col cols="3" class="text-right"> <span class="text-danger">-</span> 50% </b-col>
        </b-row>

        <!-- chart -->
        <div>
          <div class="fs-13px font-weight-600 mb-3">SESSIONS OVER TIME</div>
          <div class="chart mb-2">
            <line-chart :data="chart2.data" :options="chart2.options" class="w-100" style="height: 190px"></line-chart>
          </div>
          <div class="d-flex align-items-center">
            <i class="fa fa-square text-gray-300 mr-2 ml-auto"></i>
            <span class="fs-12px mr-4">{{ prevDate }}</span>
            <i class="fa fa-square text-blue mr-2"></i>
            <span class="fs-12px">{{ todayDate }}</span>
          </div>
        </div>
      </b-card>
      <!-- END card -->

      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-3">
          <div class="flex-fill font-weight-600 fs-16px">
            Top product by units sold
          </div>
        </div>

        <!-- list -->
        <div>
          <b-row class="mb-2">
            <b-col cols="6">iPhone 11 Pro Max</b-col>
            <b-col cols="3" class="text-center">329</b-col>
            <b-col cols="3" class="text-center"><span class="text-success">+</span> 25%</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="6">iPad Pro</b-col>
            <b-col cols="3" class="text-center">219</b-col>
            <b-col cols="3" class="text-center"><span class="text-danger">-</span> 5.2%</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="6">Macbook Pro</b-col>
            <b-col cols="3" class="text-center">125</b-col>
            <b-col cols="3" class="text-center"><span class="text-success">+</span> 2.3%</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="6">iPhone SE 2</b-col>
            <b-col cols="3" class="text-center">92</b-col>
            <b-col cols="3" class="text-center"><span class="text-success">+</span> 4.9%</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="6">Apple pencil</b-col>
            <b-col cols="3" class="text-center">52</b-col>
            <b-col cols="3" class="text-center"><span class="text-success">+</span> 25%</b-col>
          </b-row>
        </div>
      </b-card>
      <!-- END card -->

      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-2">
          <div class="flex-fill font-weight-600 fs-16px">
            Returning customer rate
          </div>
        </div>

        <!-- stats -->
        <div class="d-flex align-items-center mb-4 h3">
          <div>52.85%</div>
          <small class="fw-400 ml-auto text-danger">-7%</small>
        </div>

        <!-- chart -->
        <div>
          <div class="fs-13px font-weight-600 mb-3">CUSTOMERS</div>
          <div class="chart mb-2">
            <line-chart :data="chart3.data" :options="chart3.options" class="w-100" style="height: 190px"></line-chart>
          </div>
          <div class="d-flex align-items-center">
            <i class="fa fa-square text-indigo mr-2 ml-auto"></i>
            <span class="fs-12px mr-4">First-time</span>
            <i class="fa fa-square text-teal mr-2"></i>
            <span class="fs-12px">Returning</span>
          </div>
        </div>
      </b-card>
      <!-- END card -->

      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-2">
          <div class="flex-fill font-weight-600 fs-16px">Conversion rate</div>
          <a href="#">View report</a>
        </div>

        <!-- stats -->
        <div class="d-flex align-items-center mb-4 h3">
          <div>5.29%</div>
          <small class="fw-400 ml-auto text-success">+83%</small>
        </div>

        <!-- list -->
        <div>
          <div class="fs-13px font-weight-600 mb-3">CONVERSION FUNNEL</div>
          <b-row class="mb-2">
            <b-col cols="6">
              <div>Added to cart</div>
              <div class="text-gray-700 fs-13px">55 session</div>
            </b-col>
            <b-col cols="3" class="text-center">25.28%</b-col>
            <b-col cols="3" class="text-center"><span class="text-danger">-</span> 5%</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="6">
              <div>Reached checkout</div>
              <div class="text-gray-700 fs-13px">25 session</div>
            </b-col>
            <b-col cols="3" class="text-center">15.28%</b-col>
            <b-col cols="3" class="text-center"><span class="text-success">+</span> 82%</b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <div>Sessions converted</div>
              <div class="text-gray-700 fs-13px">5 session</div>
            </b-col>
            <b-col cols="3" class="text-center">5.28%</b-col>
            <b-col cols="3" class="text-center"><span class="text-success">+</span> 82%</b-col>
          </b-row>
        </div>
      </b-card>
      <!-- END card -->

      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-2">
          <div class="flex-fill font-weight-600 fs-16px">
            Average order value
          </div>
        </div>

        <!-- stats -->
        <div class="d-flex align-items-center mb-4 h3">
          <div>$35.12</div>
          <small class="fw-400 ml-auto text-danger">-3.2%</small>
        </div>

        <!-- chart -->
        <div>
          <div class="chart mb-2">
            <line-chart :data="chart4.data" :options="chart4.options" class="w-100" style="height: 190px"></line-chart>
          </div>
          <div class="d-flex align-items-center">
            <i class="fa fa-square text-gray-300 mr-2 ml-auto"></i>
            <span class="fs-12px mr-4">{{ prevDate }}</span>
            <i class="fa fa-square text-blue mr-2"></i>
            <span class="fs-12px">{{ todayDate }}</span>
          </div>
        </div>
      </b-card>
      <!-- END card -->

      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-2">
          <div class="flex-fill font-weight-600 fs-16px">Total orders</div>
        </div>

        <!-- stats -->
        <div class="d-flex align-items-center mb-4 h3">
          <div>12</div>
          <small class="fw-400 ml-auto text-success">+57%</small>
        </div>

        <!-- chart -->
        <div>
          <div class="fs-13px font-weight-600 mb-3">ORDERS OVER TIME</div>
          <div class="chart mb-2">
            <line-chart :data="chart5.data" :options="chart5.options" class="w-100" style="height: 190px"></line-chart>
          </div>
          <div class="d-flex align-items-center">
            <i class="fa fa-square text-gray-300 mr-2 ml-auto"></i>
            <span class="fs-12px mr-4">{{ prevDate }}</span>
            <i class="fa fa-square text-blue mr-2"></i>
            <span class="fs-12px">{{ todayDate }}</span>
          </div>
        </div>
      </b-card>
      <!-- END card -->

      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-3">
          <div class="flex-fill font-weight-600 fs-16px">
            Top pages by sessions
          </div>
        </div>

        <!-- list -->
        <b-row class="mb-2">
          <b-col cols="6"
            ><div><a href="#">/phone/apple-11-pro-max</a></div></b-col
          >
          <b-col cols="3" class="text-center">15</b-col>
          <b-col cols="3" class="text-center"><span class="text-success">+</span> 15%</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6"
            ><div><a href="#">/tablet/apple-ipad-pro-128gb</a></div></b-col
          >
          <b-col cols="3" class="text-center">12</b-col>
          <b-col cols="3" class="text-center"><span class="text-success">+</span> 8%</b-col>
        </b-row>
        <b-row>
          <b-col cols="6"
            ><div><a href="#">/desktop/apple-mac-pro</a></div></b-col
          >
          <b-col cols="3" class="text-center">4</b-col>
          <b-col cols="3" class="text-center"><span class="text-danger">-</span> 3%</b-col>
        </b-row>
      </b-card>
      <!-- END card -->

      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-3">
          <div class="flex-fill font-weight-600 fs-16px">
            Sessions by device type
          </div>
          <a href="#">View report</a>
        </div>

        <!-- list -->
        <b-row class="mb-2">
          <b-col cols="6">
            <div>Desktop</div>
          </b-col>
          <b-col cols="3" class="text-center">247</b-col>
          <b-col cols="3" class="text-center"><span class="text-success">+</span> 4.2%</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <div>Mobile</div>
          </b-col>
          <b-col cols="3" class="text-center">198</b-col>
          <b-col cols="3" class="text-center"><span class="text-danger">-</span> 2.2%</b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <div>Tablet</div>
          </b-col>
          <b-col cols="3" class="text-center">35</b-col>
          <b-col cols="3" class="text-center"><span class="text-success">+</span> 8.9%</b-col>
        </b-row>
      </b-card>
      <!-- END card -->

      <!-- BEGIN card -->
      <b-card>
        <!-- title -->
        <div class="d-flex align-items-center mb-3">
          <div class="flex-fill font-weight-600 fs-16px">
            Visits from social sources
          </div>
          <a href="#">View report</a>
        </div>

        <!-- list -->
        <b-row class="mb-2">
          <b-col cols="6">
            <div>Facebook</div>
          </b-col>
          <b-col cols="3" class="text-center">247</b-col>
          <b-col cols="3" class="text-center"><span class="text-success">+</span> 4.2%</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <div>Twitter</div>
          </b-col>
          <b-col cols="3" class="text-center">153</b-col>
          <b-col cols="3" class="text-center"><span class="text-success">+</span> 8.2%</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <div>Instagram</div>
          </b-col>
          <b-col cols="3" class="text-center">98</b-col>
          <b-col cols="3" class="text-center"><span class="text-danger">-</span> 3.4%</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="6">
            <div>Pinterest</div>
          </b-col>
          <b-col cols="3" class="text-center">75</b-col>
          <b-col cols="3" class="text-center"><span class="text-success">+</span> 1.9%</b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <div>Dribbble</div>
          </b-col>
          <b-col cols="3" class="text-center">22</b-col>
          <b-col cols="3" class="text-center"><span class="text-success">+</span> 2.1%</b-col>
        </b-row>
      </b-card>
      <!-- END card -->
    </b-card-group>
    <!-- END card-columns -->
  </div>
  <!-- END #content -->
</template>

<script>
import LineChart from '../components/vue-chartjs/LineChart'
import moment from 'moment'

export default {
  name: 'analytics',
  components: { LineChart },
  methods: {
    newDate(days) {
      return moment()
        .add(days, 'd')
        .format('D MMM')
    },
    updateValues(d) {
      const startDate = moment(d.startDate)
      const endDate = moment(d.endDate)
      const gap = endDate.diff(startDate, 'days')

      this.dateRange.range.prevStartDate = moment(startDate)
        .subtract('days', gap)
        .format('D MMMM')
      this.dateRange.range.prevEndDate = moment(startDate)
        .subtract('days', 1)
        .format('D MMMM YYYY')
    },
    formatDate(d) {
      const monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      d = new Date(d)
      d = monthsName[d.getMonth()] + ' ' + d.getDate()
      return d
    },
  },
  filters: {
    date: function(value) {
      if (value) {
        return moment(String(value)).format('D MMMM YYYY')
      }
    },
  },
  data() {
    return {
      prevDate: moment()
        .add(-1, 'd')
        .format('D MMM YYYY'),
      todayDate: moment().format('D MMM YYYY'),
      dateRange: {
        opens: 'right',
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: false,
        linkedCalendars: false,
        range: {
          startDate: moment().subtract(7, 'days'),
          endDate: moment(),
          prevStartDate: moment()
            .subtract('days', 15)
            .format('D MMMM'),
          prevEndDate: moment()
            .subtract('days', 8)
            .format('D MMMM YYYY'),
        },
      },
      chart1: {
        data: {
          labels: ['', '4am', '8am', '12pm', '4pm', '8pm', this.newDate(1)],
          datasets: [
            {
              color: '#1f6bff',
              backgroundColor: 'transparent',
              borderColor: '#1f6bff',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#1f6bff',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 0, 0, 601.5, 220],
            },
            {
              color: '#c9d2e3',
              backgroundColor: 'rgba(201, 210, 227, .2)',
              borderColor: '#c9d2e3',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#c9d2e3',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 0, 0, 500, 120, 0, 0, 0],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.000001,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 750,
                  stepSize: 250,
                },
              },
            ],
          },
        },
      },
      chart2: {
        data: {
          labels: ['', '4am', '8am', '12pm', '4pm', '8pm', this.newDate(1)],
          datasets: [
            {
              color: '#1f6bff',
              backgroundColor: 'transparent',
              borderColor: '#1f6bff',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#1f6bff',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 20, 50, 100, 120],
            },
            {
              color: '#c9d2e3',
              backgroundColor: 'rgba(201, 210, 227, .2)',
              borderColor: '#c9d2e3',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#c9d2e3',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 30, 44, 130, 34, 15, 43, 22],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.000001,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 150,
                  stepSize: 50,
                },
              },
            ],
          },
        },
      },
      chart3: {
        data: {
          labels: ['', '4am', '8am', '12pm', '4pm', '8pm', this.newDate(1)],
          datasets: [
            {
              color: '#640DF3',
              backgroundColor: 'transparent',
              borderColor: '#640DF3',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#640DF3',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 0, 5, 18, 9],
            },
            {
              color: '#0cd096',
              backgroundColor: 'rgba(12, 208, 150, .2)',
              borderColor: '#0cd096',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#0cd096',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 0, 10, 26, 13],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.000001,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 30,
                  stepSize: 10,
                },
              },
            ],
          },
        },
      },
      chart4: {
        data: {
          labels: ['', '4am', '8am', '12pm', '4pm', '8pm', this.newDate(1)],
          datasets: [
            {
              color: '#1f6bff',
              backgroundColor: 'transparent',
              borderColor: '#1f6bff',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#1f6bff',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 0, 0, 24, 39],
            },
            {
              color: '#c9d2e3',
              backgroundColor: 'rgba(201, 210, 227, .2)',
              borderColor: '#c9d2e3',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#c9d2e3',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 0, 0, 28, 35, 23, 0, 0],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.000001,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 60,
                  stepSize: 20,
                },
              },
            ],
          },
        },
      },
      chart5: {
        data: {
          labels: ['', '4am', '8am', '12pm', '4pm', '8pm', this.newDate(1)],
          datasets: [
            {
              color: '#1f6bff',
              backgroundColor: 'transparent',
              borderColor: '#1f6bff',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#1f6bff',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 0, 0, 12, 5],
            },
            {
              color: '#c9d2e3',
              backgroundColor: 'rgba(201, 210, 227, .2)',
              borderColor: '#c9d2e3',
              borderWidth: 2,
              pointBackgroundColor: '#ffffff',
              pointBorderWidth: 2,
              pointRadius: 4,
              pointHoverBackgroundColor: '#ffffff',
              pointHoverBorderColor: '#c9d2e3',
              pointHoverRadius: 6,
              pointHoverBorderWidth: 2,
              data: [0, 0, 0, 10, 4, 2, 0, 0],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.000001,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 15,
                  stepSize: 5,
                },
              },
            ],
          },
        },
      },
    }
  },
}
</script>
